<template>
  <div>
    <v-row class="mt-3 pt-5">
      <v-col cols="auto" align-self="center">
        <v-btn icon to="/verein" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>mdi-account-supervisor-circle</v-icon>
      </v-col>
      <v-col align-self="center">
        <h3 class="white--text font-weight-bold">
          ABTEILUNGEN VERWALTEN
        </h3>
      </v-col>
      <v-col cols="auto">
        <v-btn
          :fab="$vuetify.breakpoint.smAndDown"
          :text="$vuetify.breakpoint.mdAndUp"
          @click="newabteilung = true"
        >
          <v-icon class="mr-md-2">mdi-plus</v-icon>
          <span v-if="$vuetify.breakpoint.mdAndUp">Neue Abteilung</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto" class="pb-0" align-self="center"></v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" class="pb-0" align-self="center">
        <v-btn
          text
          small
          @click="
            sortierung == 'Name (absteigend)'
              ? (sortierung = 'Name (aufsteigend)')
              : (sortierung = 'Name (absteigend)')
            sortieren()
          "
        >
          <v-icon class="mr-2">
            {{
              sortierung == 'Name (absteigend)'
                ? 'mdi-sort-descending'
                : 'mdi-sort-ascending'
            }}
          </v-icon>
          Name
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-row justify="center" v-if="user.data.abteilungen">
        <v-col
          cols="11"
          class="pa-0 ma-0"
          v-for="(abteilung, index) in user.data.abteilungen"
          :key="index"
        >
          <cardbutton
            :title="abteilung.name"
            icon="mdi-account-supervisor-circle"
            :textcolor="template.colors.block_text"
            :bgcolor="template.colors.blocks"
            upper
            chev
            :to="`/verein/abteilungen/${index}`"
          />
        </v-col>
      </v-row>
      <v-col
        cols="12"
        v-if="!user.data.abteilungen || user.data.abteilungen.length == 0"
      >
        <v-card dark>
          <v-row style="min-height: 30vh;" justify="center" v-if="wizard == 0">
            <v-col cols="11" align-self="center" class="text-center">
              <h3 class="font-weight-light">
                Es wurden derzeit noch keine Abteilungen in deinem Verein
                angelegt.
              </h3>
            </v-col>
            <v-col cols="11" class="text-center">
              <v-btn color="primary" large @click="newabteilung = true">
                Abteilung erstellen
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <CreateAbteilung
      v-if="newabteilung"
      v-on:closing="
        newabteilung = false
        sortieren()
      "
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../../firebaseConfig'
import router from '../../../routes/index'
import store from '../../../store'
import cardbutton from '../../../elements/cards/card-button'
import CreateAbteilung from './createAbteilung'

export default {
  name: 'Anwesenheitsliste',
  data() {
    return {
      benutzer: [],
      abteilungen: [],
      loading: false,
      datum: '',
      adatum: false,
      wizard: 0,
      newabteilung: false,
      sortierung: 'Name (aufsteigend)',
    }
  },
  components: {
    CreateAbteilung,
    cardbutton,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  mounted() {
    if (this.template.var.deactivate_abteilung) {
      router.push('/verein')
    }
    this.abteilungen = this.user.data.abteilungen
    this.sortieren()
  },
  methods: {
    addNull(val) {
      if (val > 10) {
        return val
      }
      return '0' + val
    },
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    sortieren() {
      this.abteilungen = this.user.data.abteilungen
      function compareNameASC(a, b) {
        if (a.name > b.name) {
          return 1
        } else {
          return -1
        }
      }
      function compareNameDESC(a, b) {
        if (a.name > b.name) {
          return -1
        } else {
          return 1
        }
      }

      if (this.sortierung == 'Name (aufsteigend)') {
        this.abteilungen.sort(compareNameASC)
      }
      if (this.sortierung == 'Name (absteigend)') {
        this.abteilungen.sort(compareNameDESC)
      }
    },
  },
}
</script>
