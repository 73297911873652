<template>
  <v-dialog
    value="true"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :persistent="$vuetify.breakpoint.lgAndUp"
    transition="dialog-bottom-transition"
    :max-width="$vuetify.breakpoint.lgAndUp ? '600' : undefined"
    dark
    style="padding-top: env(safe-area-inset-top);"
  >
    <v-card style="width: 100vw; overflow-x: hidden;">
      <v-toolbar
        fixed
        :color="template.colors.primary"
        :style="`color: ${template.colors.inline_primary_text};`"
        style="
          padding-top: env(safe-area-inset-top);
          height: 80px + env(safe-area-inset-top);
        "
      >
        <v-toolbar-title>Neue Abteilung</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closing()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row justify="center" class="mt-4">
        <v-col cols="10" lg="8" class="px-2">
          <v-text-field
            v-model="abteilung.name"
            outlined
            label="Name der Abteilung"
          ></v-text-field>
          <v-divider></v-divider>
          <v-btn
            class="my-4"
            block
            large
            :loading="creating"
            :color="template.colors.primary"
            :style="`color: ${template.colors.inline_primary_text};`"
            :disabled="!abteilung.name"
            @click="create()"
          >
            <v-icon class="mr-2">mdi-plus</v-icon>
            Erstellen
          </v-btn>
          <v-btn
            class="my-4"
            block
            large
            outlined
            :color="template.colors.primary"
            :style="`color: ${template.colors.inline_primary_text};`"
            @click="closing()"
          >
            Abbrechen
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
//import firebase from '../../../firebaseConfig'
import { mapGetters } from 'vuex'

export default {
  name: 'Abteilung-erstellen',
  data() {
    return {
      abteilung: {
        name: '',
      },
      creating: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
  methods: {
    closing() {
      this.$emit('closing', true)
    },
    create() {
      this.creating = true
      if (this.user.data.abteilungen) {
        this.user.data.abteilungen.push(this.abteilung)
      } else {
        this.user.data.abteilungen = []
        this.user.data.abteilungen.push(this.abteilung)
      }
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .update({
          abteilungen: this.user.data.abteilungen,
        })
        .then(() => {
          this.creating = false
          this.closing()
        })
    },
  },
}
</script>
